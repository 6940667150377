.contactanos-section {
    position: relative;
    height: 100vh;
}
.contactanos-section h1{
    width: 100% !important;
}
.contactanos-section::before {
    content: "";
    background-image: url("../../images/sdsds.png.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
/* Responsive */
/* Estilos para tablets en orientación horizontal o pantallas pequeñas */
@media (1024px <= width <= 1200px) {
}
/* Estilos para tablets en orientación vertical */
@media (768px <= width <= 1024px) {
}
/*  Estilos para teléfonos medianos y grandes */
@media (480px <= width <= 768px) {
}
/* Estilos para teléfonos pequeños */
@media (width <= 480px) {
    /* Primera seccion */
    .contactanos-section div{
        padding-left: 10px !important;
    }

    /* Tercera seccion */
    .form-contact form div:nth-child(6), .form-contact form div:nth-child(7){
        display: flex;
        align-items: center;
        justify-content: start;
        font-size: 10px ;

    }
}