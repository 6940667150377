.conectividad-section {
    position: relative;
    height: 100vh;
}
.conectividad-section::before {
    content: "";
    background-image: url("../../../images/EMPRESAS-.png.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.headForm {
    background-color: #191919;
    height: 400px;
    width: 400px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 15px;
}
.headForm input {
    background-color: #2E2E2E;
    border: none;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 3px 10px;
    color: white;
}
.headForm .botton {
    background-color: #1FB3AE;
    height: auto;
    margin-top: 20px;
    width: 120px;
    text-align: center;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    color: white;
    border: none;
    transition: all ease-in-out.2s;
    -webkit-transition: all ease-in-out.2s;
    -moz-transition: all ease-in-out.2s;
    -ms-transition: all ease-in-out.2s;
    -o-transition: all ease-in-out.2s;
}
.headForm .botton:hover {
    background-color: #2E2E2E;
    border: none;
}


.row-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
}


.img-responsive-custom {
    max-width:  150% !important; 
    height: auto;     /* Mantiene la proporción de la imagen */
}

@media (min-width: 768px) { 
    .img-responsive-custom {
        max-width: none; /* Permite que en pantallas grandes tenga su tamaño original */
    }
}

.texto-ajustado {
    margin-left: -70px; /* Se pega a la izquierda en pantallas grandes */
}

@media (max-width: 768px) { 
    .texto-ajustado {
        margin-left: 0; /* Se ajusta al centro en pantallas pequeñas */
    }
}


/* Estilos para tablets en orientación horizontal o pantallas pequeñas */
@media (1024px <= width <= 1200px) {
}
/* Estilos para tablets en orientación vertical */
@media (768px <= width <= 1024px) {
}
/*  Estilos para teléfonos medianos y grandes */
@media (480px <= width <= 768px) {
}
/* Estilos para teléfonos pequeños */
@media (width <= 480px) {
    /* Priemra seccion */
    .conectividad-section div {
        margin-left: 0px !important;
        margin-top: 30px !important;
    }
    .conectividad-section h1{
        font-size: 40px !important;
        width: 100% !important;
        text-align: center;
    }
    .conectividad-section .content-headForm{
        display: none !important;
    }

    /* Segunda seccion */
    .seccion-sub-servicios {
        padding-bottom: 40px !important;
    }
    .seccion-sub-servicios img {
        display: none !important;
    }
    .seccion-sub-servicios div div:nth-child(2) {
        gap: 30px;
    }
    .seccion-sub-servicios div div:nth-child(2) p, .seccion-sub-servicios div div:nth-child(2) h1{
        text-align: left !important;
    }
    .seccion-sub-servicios div div:nth-child(2) div:nth-child(1){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }

    /* Tercera seccion */
    .help .img img{
        display: none !important;
    }

}