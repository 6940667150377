.satelital-section {
    position: relative;
    height: 100vh;
}
.satelital-section::before {
    content: "";
    background-image: url("../../../images/er.png.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.seccion-puerta-satel .col-md-4{
    width: 300px;
}
.seccion-puerta-satel .design{
    background-color: #3A4040;
    border-top-left-radius: 400px;
    border-top-right-radius: 400px;
    color: white;
    min-height: 600px;
}
.seccion-puerta-satel .design .mb-auto{
    margin-bottom: 30px !important;
}


/* Responsive */
/* Estilos para tablets en orientación horizontal o pantallas pequeñas */
@media (1024px <= width <= 1200px) {
}
/* Estilos para tablets en orientación vertical */
@media (768px <= width <= 1024px) {
}
/*  Estilos para teléfonos medianos y grandes */
@media (480px <= width <= 768px) {
}
/* Estilos para teléfonos pequeños */
@media (width <= 480px) {
    /* Priemra seccion */
    .satelital-section div {
        margin-left: 0px !important;
        margin-top: 30px !important;
    }
    .satelital-section h1{
        font-size: 40px !important;
        width: 100% !important;
        text-align: center;
    }
    .satelital-section .content-headForm{
        display: none !important;
    }

    /* Segunda seccion */
    .servicio-internet .row div {
        padding: 0 !important;
    }
    .servicio-internet .row h1 {
        font-size: 30px !important;
        text-align: center;
    }
    .servicio-internet img {
        margin-top: 20px ;
        height: 100% !important;
        width: 90% !important;
    }

    /* Tercera seccion */
    .beneficios-internet {
        gap: 30px !important;
    }
    .seccion-puerta-satel .design{
        min-height: auto !important;
        padding-bottom: 20px;
    }
}