.gestionados-section {
    position: relative;
    height: 100vh;
}
.gestionados-section::before {
    content: "";
    background-image: url("./../../../images/14.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    filter: brightness(.6);
    -webkit-filter: brightness(.6);
}

/* Estilos para tablets en orientación horizontal o pantallas pequeñas */
@media (1024px <= width <= 1200px) {
}
/* Estilos para tablets en orientación vertical */
@media (768px <= width <= 1024px) {
}
/*  Estilos para teléfonos medianos y grandes */
@media (480px <= width <= 768px) {
}
/* Estilos para teléfonos pequeños */
@media (width <= 480px) {
        /* Priemra seccion */
        .gestionados-section div {
            margin-left: 0px !important;
            margin-top: 30px !important;
        }
        .gestionados-section h1{
            font-size: 40px !important;
            width: 100% !important;
            text-align: center;
        }
        .gestionados-section .content-headForm{
            display: none !important;
        }
}