.scirt-section {
    position: relative;
    height: 100vh;
}
.scirt-section::before {
    content: "";
    background-image: url("../../../images/diseño20.png.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.protect {
    position: relative;
    min-height: 250px;
    display: flex;
    padding: 20px;
}

.protect::before {
    content: "";
    background-image: url("../../../images/asdasdasd.png.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    filter: brightness(20%);
}

.text-primary {
    color: #05AFA5 !important;
}
.imagen-as{
    width: 400px;
    height: 400px;
    object-fit: cover;
    margin-left: 150px;
}