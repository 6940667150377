.seguridad-section {
    position: relative;
    height: 100vh;
}
.seguridad-section::before {
    content: "";
    background-image: url("./../../../images/12.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.seccion-puerta-seg .col-md-4{
    width: 325px;
    margin-left: 50px;
}
.seccion-puerta-seg .design{
    background-color: #3A4040;
    border-top-left-radius: 400px;
    border-top-right-radius: 400px;
    color: white;
    min-height: 650px;
    text-align: start !important;
}

/* Estilos para tablets en orientación horizontal o pantallas pequeñas */
@media (1024px <= width <= 1200px) {
}
/* Estilos para tablets en orientación vertical */
@media (768px <= width <= 1024px) {
}
/*  Estilos para teléfonos medianos y grandes */
@media (480px <= width <= 768px) {
}
/* Estilos para teléfonos pequeños */
@media (width <= 480px) {
    /* Priemra seccion */
    .seguridad-section div {
        margin-left: 0px !important;
        margin-top: 30px !important;
    }
    .seguridad-section h1{
        font-size: 40px !important;
        width: 100% !important;
        text-align: center;
    }
    .seguridad-section .content-headForm{
        display: none !important;
    }
}