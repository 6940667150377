.sap-section {
    position: relative;
    height: 100vh;
}
.sap-section::before {
    content: "";
    background-image: url("../../../images/diseño15.png.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    filter: brightness(.6);
    -webkit-filter: brightness(.6);
}

.text-img-sap1, .text-img-sap2 {
    position: relative;
}
.text-img-sap1::before {
    content: "";
    background-image: url("../../../images/77.png.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    filter: brightness(30%);
    -webkit-filter: brightness(30%);
}
.text-img-sap2::before {
    content: "";
    background-image: url("../../../images/78.png.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    filter: brightness(30%);
    -webkit-filter: brightness(30%);
}


.img-responsive-customm {
    max-width: 65%;  /* La imagen no se desborda */
    height: auto;     /* Mantiene la proporción */
}

@media (min-width: 992px) { 
    .img-responsive-custom {
        max-width: 45%;  /* En pantallas grandes, usa solo el 75% del contenedor */
    }
}