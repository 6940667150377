/* ESTILO BASE DEL BOTÓN */
.boton-animado {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    width: auto;
    border-radius: 40px;
    border: 1px solid white;
    padding: 0 10px;
    overflow: hidden;
    cursor: pointer;
    color: white;
    transition: color 0.3s ease, background 0.3s ease;
    -webkit-transition: color 0.3s ease, background 0.3s ease;
    -moz-transition: color 0.3s ease, background 0.3s ease;
    -ms-transition: color 0.3s ease, background 0.3s ease;
    -o-transition: color 0.3s ease, background 0.3s ease;
}

/* Fondo animado */
.boton-animado .hover-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: white;
    transition: width 0.3s ease;
    z-index: 1;
}

/* Ícono */
.boton-animado i {
    font-size: 40px;
    color: white;
    position: relative;
    left: -11.5px;
    transition: transform 0.3s ease, color 0.3s ease;
    z-index: 2;
}

/* Texto */
.boton-animado span {
    position: relative;
    z-index: 2;
    padding-left: 10px;
}

/* Estado HOVER (Solo si NO está activo) */
.boton-animado:not(.activo):hover .hover-bg {
    width: 100%;
}

.boton-animado:not(.activo):hover {
    color: black;
}

.boton-animado:not(.activo):hover i {
    color: #1FB3AE;
    transform: rotate(90deg);
}

/* Estado ACTIVO (cuando se presiona) */
.boton-animado.activo {
    color: black;
}

.boton-animado.activo .hover-bg {
    width: 100%;
}

.boton-animado.activo i {
    color: #1FB3AE;
    transform: rotate(90deg);
}

/* ANIMACIÓN DEL TEXTO OCULTO */
.parrafo-oculto {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.parrafo-oculto.visible {
    max-height: 200px;
    opacity: 1;
}

.lideres {
    min-height: 500px;
    position: relative;
}

.lideres::before {
    content: "";
    background-image: url("../../../images/10.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}


/* Sección con fondo oscuro */
.seccion-principios {
    width: 100%;
    margin: auto;
    padding: 20px;
    background-color: #3A4040;
    padding-bottom: 40px;
}

/* Título principal de la sección */
.seccion-principios .titulo-an {
    font-family: "Red Hat Display" ;
    font-weight: bold;
    font-size: 50px;
    color: white;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

/* Contenedor de la imagen */
.image-container {
    position: relative;
    overflow: hidden;
}

/* Imagen de la tarjeta */
.card-img-top {
    width: 100%;
    height: auto;
    transition: filter 0.3s ease-in-out;
    -webkit-transition: filter 0.3s ease-in-out;
    -moz-transition: filter 0.3s ease-in-out;
    -ms-transition: filter 0.3s ease-in-out;
    -o-transition: filter 0.3s ease-in-out;
}

/* Título centrado sobre la imagen (oculto por defecto) */
.titulo-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: normal;
    font-size: 24px;
    text-transform: none;
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
    text-align: center;
    width: 100%;
    opacity: 0; /* Oculto por defecto */
    transition: opacity 0.3s ease-in-out;
}

/* Efecto al pasar el cursor */
.card:hover .titulo-overlay {
    opacity: 1; /* Muestra el título */
}

.card:hover .card-img-top {
    filter: brightness(50%); /* Aplica filtro de brillo en hover */
}

/* Estilo de la tarjeta */
.card {
    position: relative;
    box-shadow: 3px 0px 10px black;
    min-height: 450px;
    transition: 0.3s ease-in-out;
}

/* Contenedor de texto */
.card-body {
    background-color: white;
    color: black;
    padding: 20px;
}

/* Texto dentro de la tarjeta */
.card-text {
    font-size: 16px;
    text-align: center;
}


/* Responsive */

/* Estilos para teléfonos pequeños */
@media (width <= 480px) {
    /* Primera seccion  */
    section .imagen-somos-inicio {
        display: none !important;
    }

    /* Segunda seccion */
    .lideres {
        min-height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .lideres h1 {
        font-size: 1.8rem !important;
    }
    .lideres div {
        width: auto !important;
    }

    /* Tercera sección */
    section.clientes .row div:nth-child(1) div h1 {
        font-size: 2.2rem !important;
        width: 100% !important;
    }
    section.clientes .row div.clientes-beneficios {
        margin-top: 35px;
        padding: 0 !important;
    }
    section.clientes .row img {
        width: 75%;
    }
    section.clientes .row div.clientes-beneficios div:nth-child(5) p {
        font-size: 14px !important;
    }

    /* Cuarta seccion */
    .seccion-principios .card {
        margin-bottom: 30px !important;
    }

}
/* Estilos para teléfonos medianos y grandes */
@media (480px <= width <= 768px) {

}

/* Estilos para tablets en orientación vertical */
@media (768px <= width <= 1024px) {

}

/* Estilos para tablets en orientación horizontal o pantallas pequeñas */
@media (1024px <= width <= 1200px) {

}