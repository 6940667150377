.cobertura-section {
    position: relative;
    height: 100vh;
}
.cobertura-section h1 {
    width: 90% !important;
}
.cobertura-section::before {
    content: "";
    background-image: url("../../../images/portada.png.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.mapaperu{
    width: auto;
    max-height: 500px;
}
.filaFigura{
    padding-top: 50px !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    background-color: #1A1A1A;
    color: white;
}
.figuraGeo{
    border-top-left-radius: 400px; /* Ajusta el radio de la esquina superior izquierda */  
    border-top-right-radius: 400px; /* Ajusta el radio de la esquina superior derecha */  
    /* outline: 2px #09AEAF solid; */
    border-top: 1px solid #09AEAF;  /* Borde superior */  
    border-left: 1px solid #09AEAF; /* Borde izquierdo */  
    border-right: 1px solid #09AEAF; /* Borde derecho */  
    border-bottom: none;
    padding: 20px;
}
.figuraGeo h1{
    font-size: 50px;
}
.imagenVineta{
    padding-top: 30px;
}
.imagenVineta img{
    max-height: 250px;
    width: auto;
}

/* Estilos para tablets en orientación horizontal o pantallas pequeñas */
@media (1024px <= width <= 1200px) {
}
/* Estilos para tablets en orientación vertical */
@media (768px <= width <= 1024px) {
}
/*  Estilos para teléfonos medianos y grandes */
@media (480px <= width <= 768px) {
}
/* Estilos para teléfonos pequeños */
@media (width <= 480px){
    /* Primera seccion */
    .cobertura-section div{
        padding-left: 20px !important;
    }

    /* Segunda seccion */
    .red-en-peru h1 {
        width: 100% !important;
    }
    .red-en-peru img {
        width: 95% !important;
        margin-top: 15px;
    }

    /* Tercera Seccion */
    .filaFigura {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    .figuraGeo {
        margin-bottom: 40px !important;
        border-bottom: 1px solid #09AEAF; /* Borde derecho */  
    }

    /* Cuarta seccion */
    .formulario form div:nth-child(4), .formulario form div:nth-child(5)  {
        font-size: 10px !important;
        display: flex;
        justify-content: start;
        align-items: center;
    
    }
}